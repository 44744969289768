//TODO: Add to stories
import classNames from "classnames"
import React, { useCallback, useContext, useEffect, useState } from "react"

import PillButton from "./PillButton"
import {
  useAddToCart,
  useAddMultipleToCart,
  useUpdateCartQuantity,
} from "../../../../cart/hooks"
import { CartContext } from "../../../../cart/store"
import { Color } from "../../../../constants/V2/color"
import {
  bundleAlreadyInCart,
  productAlreadyInCart,
  productAlreadyInCartInBundle,
} from "../../../../utils/shop"
import Checkmark from "../Checkmark"
import PlusIcon from "../PlusIcon"

import { trackSegmentEvent } from "@utils/analytics"
import { formatPrice } from "@utils/price"
import { getAccentColor, getFillColorClass } from "@utils/V2/color"

export type Product = {
  id: string
  sku: string
}

type Props = {
  style?: "solid" | "bordered"
  size?: "small" | "medium" | "large"
  hideIcon?: boolean
  className?: string
  bundleId?: string
  bundleName?: string
  products: Product[]
  quantity?: number
  changeCartQuantity?: boolean
  reasonNotAvailable?: string
  buttonColor?: Color
  price?: number
  overrideTextColor?: Color
  onClick?: () => void
}

const AddToCartButton = ({
  style = "bordered",
  size = "large",
  hideIcon,
  className,
  bundleId,
  bundleName,
  products,
  quantity = 1,
  changeCartQuantity,
  reasonNotAvailable,
  buttonColor = Color.Blue,
  price,
  overrideTextColor = getAccentColor(buttonColor),
  onClick,
}: Props) => {
  const cartContext = useContext(CartContext)

  //TODO consolidate these 3 into one call. and pass into add to cart button
  const alreadyAddedInBundle = productAlreadyInCartInBundle(products[0].sku)
  const alreadyAdded = bundleId
    ? bundleAlreadyInCart(bundleId)
    : alreadyAddedInBundle || productAlreadyInCart(products[0].sku)

  const iconStyles =
    "group-hover:fill-white-v2 group-hover:rotate-90 group-focus-visible:rotate-90 transform transition duration-300 ease-in-out"

  const addToCart = useAddToCart()
  const addMultipleToCart = useAddMultipleToCart()
  const [updateCart, setUpdateCart] = useState(
    changeCartQuantity && !alreadyAddedInBundle
  )
  const [disabled, setDisabled] = useState(false)

  const handleAddToCart = useCallback(() => {
    if (!bundleId) {
      //TODO combine this and add multiple
      addToCart(products[0].id, products[0].sku, quantity)
      trackSegmentEvent("shop_add_to_cart", {
        sku: products[0].sku,
        quantity: quantity,
      })
    } else if (bundleId && bundleName) {
      addMultipleToCart(bundleId, bundleName, products, quantity)
      products.forEach((product) => {
        trackSegmentEvent("shop_add_to_cart", {
          sku: product.sku,
          quantity: quantity,
        })
      })
    } else {
      console.warn(
        `Invalid product id: ${products[0].id} or sku: ${products[0].sku}`
      )
    }
    cartContext.setCartPreviewOpen(true)
    if (onClick) {
      onClick()
    }
    setUpdateCart(false)
  }, [quantity, addToCart, products, cartContext])

  const updateCartQuantity = useUpdateCartQuantity()

  const handleUpdateCart = useCallback(
    (products: Product[]) => {
      products.forEach((product) => {
        updateCartQuantity(product.sku, quantity, bundleId)
        setUpdateCart(false)
        if (changeCartQuantity === true) {
          setUpdateCart(true)
        }
      })
      if (onClick) {
        onClick()
      }
    },
    [changeCartQuantity, quantity, updateCartQuantity]
  )

  useEffect(() => {
    if (changeCartQuantity === true && !alreadyAddedInBundle) {
      setUpdateCart(true)
    } else {
      setUpdateCart(false)
    }
  }, [changeCartQuantity, alreadyAddedInBundle])

  useEffect(() => {
    setDisabled(cartContext.cartDisabled)
  }, [cartContext.cartDisabled])

  if (reasonNotAvailable) {
    return <p>{reasonNotAvailable}</p>
  }

  if (!alreadyAdded) {
    return (
      <PillButton
        text="Add to cart"
        secondaryText={
          price
            ? formatPrice(price * quantity, {
                hasThousandsSeparator: true,
                shouldShowDecimals: true,
              })
            : ""
        }
        iconPosition="right"
        size={size}
        style={style}
        icon={
          <PlusIcon
            className={classNames(
              getFillColorClass(overrideTextColor),
              iconStyles
            )}
          />
        }
        hideIcon={hideIcon}
        color={buttonColor}
        onClick={handleAddToCart}
        disabled={disabled}
        className={className}
        data-test-id="add-to-cart"
        overrideTextColor={overrideTextColor}
      />
    )
  }

  if (alreadyAdded && updateCart === false) {
    return (
      <PillButton
        text="In cart"
        size={size}
        style="bordered"
        icon={
          <Checkmark className="stroke-blue-v2 group-hover:stroke-white-v2" />
        }
        hideIcon={hideIcon}
        color={Color.Blue}
        className={className}
        disabled={disabled}
        overrideTextColor={Color.Blue}
      />
    )
  }

  if (alreadyAdded && updateCart === true) {
    return (
      <PillButton
        text="Update cart"
        size={size}
        style="solid"
        icon={
          <PlusIcon
            className={classNames(getFillColorClass(Color.White), iconStyles)}
          />
        }
        hideIcon={hideIcon}
        color={Color.Blue}
        onClick={() => {
          handleUpdateCart(products)
        }}
        disabled={disabled}
        className={className}
      />
    )
  }
}

export default AddToCartButton
