import { useCallback, useEffect, useRef } from "react"

import { isTablet } from "@utils/V2/screen"

/**
 * Custom hook uses IntersectionObserver to fade up animate an element
 * if the element is scrolled into view by adding animation classes and
 * animation delay styling based on the nth index of the element.
 *
 * This version uses useCallback instead of a ref parameter and useEffect.
 * It directly manipulates the node passed to it, similar to how measuredRef works.
 *
 * @param {boolean} [shouldAnimate=true] - Should hook animate (defaults to true)
 * @returns {Object} An object containing:
 * - `animation`: A callback function that should be passed to the `ref` attribute of a React element to handle scroll animations.
 * - `ref`: A React ref object for external use, allowing direct access to the DOM element being animated.
 */
export const useScrollAnimateSelf = (shouldAnimate = true) => {
  const observerRef = useRef<IntersectionObserver | null>(null)
  const externalRef = useRef<HTMLElement | null>(null)

  const callback = useCallback(
    (node: HTMLElement | null) => {
      if (!shouldAnimate || !node) return

      externalRef.current = node

      const elementClasses = "opacity-0"
      node.classList.add(elementClasses)

      const handleIntersect = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const childIndex = node.parentElement
              ? Array.from(node.parentElement.children).indexOf(node)
              : 0

            if (isTablet()) {
              node.style.animationDelay = `${childIndex * 200}ms`
            }

            node.classList.add("animate-fadeUp")

            if (observerRef.current) {
              observerRef.current.disconnect()
            }
          }
        })
      }

      const options = {
        root: null,
        rootMargin: "0%",
        threshold: 0.3,
      }

      if (observerRef.current) observerRef.current.disconnect() // Disconnect existing observer if any
      observerRef.current = new IntersectionObserver(handleIntersect, options)
      observerRef.current.observe(node)
    },
    [shouldAnimate]
  )

  // Cleanup observer when the component unmounts
  useEffect(() => {
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [])

  return { animationRefCallback: callback, ref: externalRef }
}
